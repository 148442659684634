import React from 'react';
import { Link } from 'gatsby';

const Header = () => {
    return (
        <>
            {/* <div class="PA">
                    <a class="navbar-brand text-light" href="#"><span>&lt;</span>&lt;PA&gt;<span>&rcub;</span></a>
            </div> */}
            <nav className="topnav" id="myTopnav">
                    <ul className="mx-auto navBar hideNav">
                        <li className="nav-item"><Link to="/about">about</Link></li>
                        <li className="nav-item"><Link to="/skills">skills</Link></li>
                        <li className="nav-item"><Link to="/projects">portfolio</Link></li>
                        <li className="nav-item"><Link to="/contact">contact</Link></li>
                        <li className="nav-item"><Link to="/blog">blog</Link></li>
                    </ul>
            </nav>
        </>
    )
}

export default Header