import React from 'react';
import { FaGithub, FaLinkedin, FaYoutube } from "react-icons/fa";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
    return (
        <footer className="fixed-bottom">
            <div className="p-2 mb-0">
                <a href="https://www.linkedin.com/in/adkinspeter/"><FaLinkedin size={30} className="toFront" /></a>
                <a href="https://github.com/PAdkins84"><FaGithub size={30} className="ms-3 toFront" /></a>
                <a href=""><FaYoutube size={30} className="ms-3 toFront" /></a>
                <p className="mb-1 toFront">Peter Adkins © {(new Date().getFullYear())}</p>
            </div>
        </footer>
    )
}

export default Footer